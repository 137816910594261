<template>
  <el-row type="flex" style="height: 100%;">
    <el-col :span="12" class="left">
      <img class="img-bg" src="@/page-home/assets/expert-login-bg.png" />
    </el-col>
    <el-col :span="12">
      <div class="login-container">
        <h2 class="system-title">东巴古籍数字化国际共享平台</h2>
        <el-form ref="loginForm" :model="loginForm"
                 :rules="loginRules" class="login-form" autocomplete="on"
                 label-position="left">
          <div class="title-container">
            <h3 class="title">专家登录</h3>
          </div>

          <el-form-item prop="userId">
            <el-input
                    ref="userId"
                    v-model="loginForm.userId"
                    placeholder="请输入账户ID"
                    name="userId"
                    type="text"
                    tabindex="1"
                    autocomplete="on"
            />
          </el-form-item>

          <el-tooltip v-model="capsTooltip" content="大写键盘打开" placement="right" manual>
            <el-form-item prop="password">
              <el-input
                      :key="passwordType"
                      ref="password"
                      v-model="loginForm.password"
                      :type="passwordType"
                      placeholder="请输入密码"
                      name="password"
                      tabindex="2"
                      autocomplete="on"
                      @keyup.native="checkCapslock"
                      @blur="capsTooltip = false"
                      @keyup.enter.native="handleLogin"
              />
              <span class="show-pwd" @click="showPwd">
          </span>
            </el-form-item>
          </el-tooltip>

          <el-button class="btn-login" :loading="loading" type="primary" style="width:100%;margin-bottom:30px;"
                     @click.native.prevent="handleLogin">立即登录
          </el-button>

        </el-form>
      </div>
    </el-col>
  </el-row>
</template>

<script>
  import {expertInfoDetail, expertLogin} from "@/page-home/api/expert";
  import {setToken, setTokenE} from "@/page-home/utils/storage";
  import {getUserDetail} from "@/page-home/api/home";

  export default {
    name: "ExpertLogin",
    data() {
      return {
        loginForm: {},
        loginRules: {
          userId: [{required: true, message: '请输入账户ID', trigger: 'blur'}],
          password: [{required: true, message: '请输入密码', trigger: 'blur'}]
        },
        passwordType: 'password',
        capsTooltip: false,
        loading: false,
        showDialog: false,
        redirect: undefined,
        otherQuery: {}
      }
    },

    methods: {
      checkCapslock(e) {
        const {key} = e
        this.capsTooltip = key && key.length === 1 && (key >= 'A' && key <= 'Z')
      },
      showPwd() {
        if (this.passwordType === 'password') {
          this.passwordType = ''
        } else {
          this.passwordType = 'password'
        }
        this.$nextTick(() => {
          this.$refs.password.focus()
        })
      },

      handleLogin() {
        this.$refs['loginForm'].validate(valid => {
          if (valid) {
            expertLogin(this.loginForm).then(res => {
              this.$message.success('登录成功')
              setToken('access', 'E-' + res.data.accessToken)
              setToken('refresh', res.data.refreshToken)
              expertInfoDetail().then(res => {
                this.$store.commit('SET_USER_INFO', res.data)
                setTimeout(() => {
                  this.$router.replace('/expert/review')
                }, 500)
              })
            }).catch(err => {
              this.$message.error(err.msg)
            })
          } else {
            this.$message.error('登录信息未填写完整')
            return
          }
        })

      }
    }
  }
</script>

<style lang="scss" scoped>
  $bg: #ffffff;
  $dark_gray: #889aa4;
  $light_gray: #888989;
  .left {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #F6F7FA;
  }
  .img-bg {
    width: 400px;
    height: 400px;
  }
  .login-container {
    min-height: 100%;
    width: 100%;
    background-color: $bg;
    overflow: hidden;
    .system-title {
      margin-top: 140px;
      font-size: 30px;
      text-align: center;
      color: #000000;
    }
    .login-form {
      position: relative;
      width: 520px;
      max-width: 100%;
      padding: 100px 35px 0;
      margin: 0 auto;
      overflow: hidden;
    }

    .tips {
      font-size: 14px;
      color: #fff;
      margin-bottom: 10px;

      span {
        &:first-of-type {
          margin-right: 16px;
        }
      }
    }

    .title-container {
      position: relative;
      .title {
        position: relative;
        font-size: 22px;
        color: #004DF8;
        margin: 0px auto 40px auto;
        text-align: center;
        font-weight: bold;
        &:before {
          content: '';
          position: absolute;
          left: 0;
          top: 14px;
          width: 130px;
          height: 1px;
          background-color: #B5B7B8;
        }
        &:after {
          content: '';
          position: absolute;
          right: 0;
          top: 14px;
          width: 130px;
          height: 1px;
          background-color: #B5B7B8;
        }
      }
    }

    .show-pwd {
      position: absolute;
      right: 10px;
      top: 7px;
      font-size: 16px;
      color: $dark_gray;
      cursor: pointer;
      user-select: none;
    }
  }
</style>

<style lang="scss">
  $bg: #283443;
  $light_gray: #888989;
  $cursor: #fff;
  /* reset element-ui css */
  .login-container {
    .el-input {
      display: inline-block;
      height: 47px;
      input {
        background: transparent;
        -webkit-appearance: none;
        padding: 12px 5px 12px 15px;
        color: $light_gray;
        height: 47px;
        &:-webkit-autofill {
          box-shadow: 0 0 0px 1000px $bg inset !important;
          -webkit-text-fill-color: $cursor !important;
        }
      }
    }

    .el-form-item {

      border-radius: 5px;
      color: #454545;
    }
  }
</style>

